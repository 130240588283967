<template>
  <div>
    <div class="fixed right-0 top-0 flex justify-end p-5">
      <LightDarkToggle />
    </div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                <div class="flex h-16 shrink-0 items-center gap-5">
                  <router-link to="/admin" class="flex items-center justify-center gap-5">
                    <img class="block h-[2em] w-auto" :src="logo" alt="Home Repair Network" />
                    <span class="text-white">HRNStl.org</span>
                  </router-link>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="route in routes" :key="route.name">
                          <router-link
                            :to="route.path"
                            class="block px-4 py-2 text-lg font-medium"
                            :class="[false ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']"
                            :aria-current="route.path === $route.path ? 'page' : undefined"
                          >
                            <component :is="route.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ route.name }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
        <div class="flex h-16 shrink-0">
          <router-link to="/admin" class="flex items-center justify-center gap-5">
            <img class="block h-[2em] w-auto" :src="logo" alt="Home Repair Network" />
            <span class="text-white">HRNStl.org</span>
          </router-link>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="route in routes" :key="route.name">
                  <router-link
                    :to="route.path"
                    class="block px-4 py-2 text-lg font-medium"
                    :class="[false ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']"
                    :aria-current="route.path === $route.path ? 'page' : undefined"
                  >
                    <component :is="route.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                    {{ route.name }}
                  </router-link>
                </li>
                <li class="my-10 border-b border-gray-700"></li>
                <li>
                  <ul role="list" class="mt-2 space-y-1">
                    <li v-for="item in authenticatedNav" :key="item.name">
                      <router-link
                        :to="item.path"
                        class="block px-4 py-2 text-lg font-medium"
                        :class="[false ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']"
                        :aria-current="item.path === $route.path ? 'page' : undefined"
                      >
                        <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="-mx-6 mt-auto"></li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-white"></div>
      <LightDarkToggle />
    </div>

    <main class="lg:pl-72">
      <div class="p-6">
        <div class="mx-auto">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import logo from "@/assets/logo.svg"
import "@/style.css"
import BreakpointIndicator from "@/views/partials/BreakpointIndicator.vue"
import FooterView from "@/views/partials/FooterView.vue"
import { useAuthStore } from "@/stores/AuthStore.ts"
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue"
import MainMenu from "@/views/partials/MainMenu.vue"
import NotificationsMenu from "@/NotificationsMenu.vue"
import UserMenu from "@/views/partials/UserMenu.vue"
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue"
import {
  BellIcon,
  Bars3Icon,
  UsersIcon,
  IdentificationIcon,
  UserIcon,
  XMarkIcon,
  DocumentTextIcon,
  NewspaperIcon,
  CogIcon,
  TagIcon,
  WrenchScrewdriverIcon,
  PaperClipIcon,
} from "@heroicons/vue/24/outline"
import { string } from "postcss-selector-parser"

import LoadingOverlay from "@/views/components/LoadingOverlay.vue"
import { useSettingsStore } from "@/stores/SettingsStore.ts"
import { onMounted, ref } from "vue"
import { Setting } from "@/types/hrn"
import AdminMenu from "@/views/admin/components/AdminMenu.vue"
import { BuildingOfficeIcon } from "@heroicons/vue/24/solid"
import { XCircleIcon } from "@heroicons/vue/20/solid"
import LightDarkToggle from "@/views/components/LightDarkToggle.vue"

export default {
  methods: { string },
  components: {
    LightDarkToggle,
    AdminMenu,
    LoadingOverlay,
    DisclosureButton,
    UserMenu,
    NotificationsMenu,
    MainMenu,
    Disclosure,
    DisclosurePanel,
    BreakpointIndicator,
    FooterView,
    BellIcon,
    Bars3Icon,
    XMarkIcon,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  },
  setup() {
    const settingsStore = useSettingsStore()
    const settings = ref<Setting[]>([])

    const deployed = ref(false)
    const isLoading = ref(true)
    const authenticatedNav = [
      { name: "My Profile", path: "/profile", icon: UserIcon, target: "_self" },
      { name: "Sign Out", path: "/sign-out", icon: XCircleIcon, target: "_self" },
    ]
    const routes = [
      {
        name: "Qualifiers",
        path: "/admin/qualifiers",
        role: "admin",
        icon: IdentificationIcon,
      },
      {
        name: "Categories",
        path: "/admin/categories",
        role: "admin",
        icon: TagIcon,
      },
      {
        name: "Services",
        path: "/admin/services",
        role: "admin",
        icon: WrenchScrewdriverIcon,
      },
      {
        name: "Service Organizations",
        path: "/admin/service-organizations",
        role: "admin",
        icon: BuildingOfficeIcon,
      },
      {
        name: "Users",
        path: "/admin/users",
        role: "admin",
        icon: UsersIcon,
      },
      {
        name: "Pages",
        path: "/admin/pages",
        role: "admin",
        icon: DocumentTextIcon,
      },
      {
        name: "Blog Posts",
        path: "/admin/blog-posts",
        role: "admin",
        icon: NewspaperIcon,
      },
      {
        name: "Attachments",
        path: "/admin/attachments",
        role: "admin",
        icon: PaperClipIcon,
      },
      {
        name: "Settings",
        path: "/admin/settings",
        role: "admin",
        icon: CogIcon,
      },
    ]

    const sidebarOpen = ref(false)

    onMounted(async () => {
      await settingsStore.fetchItems()
      settings.value = settingsStore.getItems()
      const deployedObj = settings.value.find((obj) => obj.name === "deployed")
      if (deployedObj) deployed.value = deployedObj.value === "true"
      else deployed.value = true
      isLoading.value = false
    })

    return {
      authenticatedNav,
      authStore: useAuthStore(),
      settings,
      deployed,
      isLoading,
      sidebarOpen,
      routes,
      logo,
    }
  },
}
</script>
