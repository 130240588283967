<template>
  <component :is="layoutComponent">
    <slot />
  </component>
</template>

<script lang="ts">
import { computed } from "vue"
import { useRoute } from "vue-router"
import PublicLayout from "@/layouts/PublicLayout.vue"
import AdminLayout from "@/layouts/AdminLayout.vue"

export default {
  components: {
    PublicLayout,
    AdminLayout,
  },
  setup() {
    const route = useRoute()
    const layoutComponent = computed(() => {
      if (route.meta.layout === "public") {
        return PublicLayout
      } else if (route.meta.layout === "admin") {
        return AdminLayout
      } else {
        return PublicLayout
      }
    })

    return { layoutComponent }
  },
}
</script>
