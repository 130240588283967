import * as turf from "@turf/turf"
import { point, polygon } from "@turf/helpers"
import { useUserInputStore } from "@/stores/UserInputStore.ts"
import { defineStore } from "pinia"
import { ServiceArea, ServiceOrganization } from "@/types/hrn"
import { createItem, destroyItem, loadItems, updateItem } from "@/stores/APIService.ts"

export const useServiceOrganizationsStore = defineStore({
  id: "ServiceOrganizations",
  state: () => ({
    items: [] as ServiceOrganization[],
    status: "idle" as "idle" | "loading" | "loaded" | "error",
    error: null as string | null,
  }),
  actions: {
    async fetchItems() {
      if (this.status === "loaded") return
      await loadItems(this, "serviceOrganizations")
    },
    async addNewItem(item: ServiceOrganization) {
      await createItem(this, "serviceOrganizations", item)
    },
    async removeItem(item: ServiceOrganization) {
      await destroyItem(this, "serviceOrganizations", item)
    },
    async changeItem(item: ServiceOrganization) {
      await updateItem(this, "serviceOrganizations", item)
    },
    getItems(): ServiceOrganization[] {
      return this.items
    },
    getItemById(id: string): ServiceOrganization | undefined {
      return this.items.find((item) => item.serviceOrganization === id)
    },
    getByUserInput() {
      const userInputStore = useUserInputStore()
      const { selectedServices, selectedQualifiers, latLng } = userInputStore

      const optimal: ServiceOrganization[] = []
      const partial: ServiceOrganization[] = []
      const noMatch: ServiceOrganization[] = []

      this.items.forEach((org: ServiceOrganization) => {
        console.log(org.serviceIds)
        console.log(org.qualifierIds)
        const serviceMatch = org.serviceIds?.some((id: string) => selectedServices.map((s) => s.service).includes(id)) ?? false
        const qualifierMatch = org.qualifierIds?.some((id: string) => selectedQualifiers.map((q) => q.qualifier).includes(id)) ?? false

        const areaMatch = org.serviceAreas?.some((area: ServiceArea) => {
          if (!area.coordinates) return false
          const pt = point([latLng.lng, latLng.lat])
          const poly = polygon([area.coordinates])
          // @ts-ignore
          return turf.booleanPointInPolygon(pt, poly)
        })

        if (serviceMatch && qualifierMatch && areaMatch) {
          optimal.push(org)
        } else if ((serviceMatch && areaMatch) || (qualifierMatch && areaMatch)) {
          partial.push(org)
        } else {
          noMatch.push(org)
        }
      })

      return { optimal, partial, noMatch }
    },
  },
})
