import { useAuthStore } from "./AuthStore.ts"
import { ApiItem, Attachment } from "@/types/hrn"

interface StoreState<T extends ApiItem> {
  items: T[]
  status: "idle" | "loading" | "loaded" | "error"
  error: string | null
}

export const authenticatedFetch = async (url: string, options?: RequestInit) => {
  const authStore = useAuthStore()
  const jwtToken = authStore.getJwtToken
  if (!jwtToken) {
    throw new Error("No JWT token found.")
  }
  await authStore.checkTokenValidity()

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  }

  const response = await fetch(url, {
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  })

  if (!response.ok) {
    const errorText = await response.text()
    throw new Error(errorText || `HTTP error! status: ${response.status}`)
  }

  return response
}

export function handleError<T extends ApiItem>(state: StoreState<T>, error: unknown): void {
  console.log("API Service: handleError", error)
  if (error instanceof Error) {
    state.error = error.message
  } else {
    state.error = "An unknown error occurred."
  }
  state.status = "error"
}

export function getEndpoint(modelType: string, item?: ApiItem, overload?: string): string {
  console.log("API Service: getEndpoint", modelType, item, overload)
  let id
  if (overload) {
    id = item?.[overload]
  } else {
    id = item?.[modelType.singularize()]
  }
  return id ? `${import.meta.env.VITE_APP_API_URL}/${modelType.apiEndpoint()}/${id}` : `${import.meta.env.VITE_APP_API_URL}/${modelType.apiEndpoint()}`
}

export function getIdField(modelType: string): string {
  return modelType.singularize() || "id"
}

export async function loadItems<T extends ApiItem>(state: StoreState<T>, modelType: string): Promise<void> {
  console.log("API Service: loadItems", modelType)
  state.status = "loading"
  const endpoint = getEndpoint(modelType)
  try {
    const response = await fetch(endpoint)
    const results: { data: T[] } = await response.json()
    state.items = results.data
    state.status = "loaded"
  } catch (error) {
    handleError(state, error)
  }
}

export async function createItem<T extends ApiItem>(state: StoreState<T>, modelType: string, item: T): Promise<void> {
  state.status = "loading"
  const endpoint = getEndpoint(modelType)
  try {
    const response = await authenticatedFetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    })
    if (response.ok) {
      await loadItems(state, modelType)
      state.status = "loaded"
    } else {
      const error = await response.text()
      throw new Error(error || "Failed to create item.")
    }
  } catch (error) {
    handleError(state, error)
  }
}

export async function destroyItem<T extends ApiItem>(state: StoreState<T>, modelType: string, item: ApiItem, overload?: string): Promise<void> {
  state.status = "loading"

  const endpoint = overload ? getEndpoint(modelType, item, overload) : getEndpoint(modelType, item)
  overload ? console.log("Overload: destroyItem", endpoint) : console.log("destroyItem", endpoint)

  try {
    const response = await authenticatedFetch(endpoint, {
      method: "DELETE",
    })
    if (response.ok) {
      const id = getIdField(modelType)
      state.items = state.items.filter((existingItem) => existingItem[id] !== item[id])
      state.status = "loaded"
    } else {
      const error = await response.text()
      throw new Error(error || "Failed to delete item.")
    }
  } catch (error) {
    handleError(state, error)
  }
}

export async function updateItem<T extends ApiItem>(state: StoreState<T>, modelType: string, item: T, overload?: string): Promise<void> {
  state.status = "loading"
  const endpoint = overload ? getEndpoint(modelType, item, overload) : getEndpoint(modelType, item)
  try {
    const response = await authenticatedFetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    })
    if (response.ok) {
      const returnedItem: T = await response.json()
      const index = state.items.findIndex((item) => item === item)
      if (index > -1) {
        state.items[index] = returnedItem
      }
      state.status = "loaded"
    } else {
      const error = await response.text()
      throw new Error(error || "Failed to update item.")
    }
  } catch (error) {
    handleError(state, error)
  }
}

export async function uploadAttachment(incomingFile: File, state: StoreState<Attachment>, modelType: string): Promise<void> {
  state.status = "loading"
  const endpoint = getEndpoint(modelType)
  console.log("uploadAttachment", endpoint)
  try {
    const formData = new FormData()
    formData.append("file", incomingFile)
    formData.append("fileName", incomingFile.name)
    formData.append("mimeType", incomingFile.type)

    const response = await authenticatedFetch(endpoint, {
      method: "POST",
      body: formData,
    })

    if (response.ok) {
      await loadItems(state, modelType)
      state.status = "loaded"
    } else {
      const error = await response.text()
      throw new Error(error || "Failed to upload attachment.")
    }
  } catch (error) {
    handleError(state, error)
  }
}
